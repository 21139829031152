<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="所属企业:" prop="compId">
            <el-select
              v-model="searchModel.compId"
              placeholder="请选择所属企业"
              filterable
              clearablecompDetailModel
              style="width: 200px"
            >
              <el-option
                v-for="item in companyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆类型:" prop="vehiType">
            <el-select
              v-model="searchModel.vehiType"
              placeholder="请选择车辆类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value,key) in vehiTypeOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆归属类型:" prop="ownType">
            <el-select
              v-model="searchModel.ownType"
              placeholder="请选择车辆归属类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                label="自备车"
                value="1"
              >
              </el-option>
              <el-option
                label="外租车"
                value="2"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号:" prop="busLicPlate">
            <el-input v-model="searchModel.busLicPlate"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              v-auth="'信息管理_车辆管理_监控车辆_新增'"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;addRow()"
            >新增车辆
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <vxe-table
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :align="allAlign"
          :data="tableData">
          <vxe-table-column type="seq" title="序号" width="60"/>
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column field="compId" title="所属企业" show-overflow="title" min-width="120" show-header-overflow="title"
                            :formatter="
                              ({cellValue, row, column }) => {
                                return cellValue?compListOptions[cellValue]:'';
                              }"/>
          />
          <vxe-table-column field="busLicPlate" title="车牌号" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <!--  <vxe-table-column field="compId" title="企业编号" show-overflow="title" min-width="60" show-header-overflow="title"/>-->
          <vxe-table-column field="ownerCode" title="自编号" show-overflow="title" min-width="80"
                            show-header-overflow="title"/>
          <!--      <vxe-table-column field="lwhTank" title="罐厢尺寸(长*宽*高)" show-overflow="title" min-width="120" show-header-overflow="title"/>
              <vxe-table-column field="lwhOut" title="外形尺寸(长*宽*高)" show-overflow="title" min-width="120" show-header-overflow="title"/>-->
          <vxe-table-column field="ownType" title="车辆归属类型" min-width="60" show-overflow="title"
                            show-header-overflow="title" :formatter="
                              ({cellValue, row, column }) => {
                                return cellValue?ownTypeOptions[cellValue]:'';
                              }"/>
          <vxe-table-column field="vehiType" title="车辆类型" min-width="60" show-overflow="title"
                            show-header-overflow="title" :formatter="
                              ({cellValue, row, column }) => {
                                return cellValue?vehiTypeOptions[cellValue]:'';z
                              }"/>
          <vxe-table-column field="vehiType" title="整备质量(吨)" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="weightRated" title="额定质量(吨)" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="totalWeight" title="总质量(吨)" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="ratedCubage" title="额定方量(方)" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <vxe-table-column field="totalCubage" title="总方量(方)" min-width="60" show-overflow="title"
                            show-header-overflow="title"/>
          <!--                <vxe-table-column field="status" title="状态" min-width="60" show-overflow="title" show-header-overflow="title":formatter="
                      ({cellValue, row, column }) => {
                        return cellValue==1?'在线':'离线';
                      }"/>-->
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)"
                         v-auth="'信息管理_车辆管理_监控车辆_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)"
                         v-auth="'信息管理_车辆管理_监控车辆_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </vxe-table>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog :title="dialogEditTitle"
                 :visible.sync="dialogEdit"
                 :v-if="dialogEdit"
                 :append-to-body="true"
                 width="850px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="160px" :model="busEditModel"
                 :rules="rules"
                 style="margin-top: 20px">
          <el-form-item label="所属企业:" prop="compId">
            <el-select
              v-model="busEditModel.compId"
              placeholder="请选择所属企业"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value,key) in compListOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号:" props="busLicPlate">
            <el-input
              v-model="busEditModel.busLicPlate"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="车辆类型:">
            <el-select
              v-model="busEditModel.vehiType"
              placeholder="请选择车辆类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value,key) in vehiTypeOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="外形尺寸(长*宽*高 /m):" props="lwhOut">
            <el-input
              v-model="busEditModel.lwhOut"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="罐厢尺寸(长*宽*高 /m):">
            <el-input
              v-model="busEditModel.lwhTank"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="车辆归属类型:" props="ownType">
            <el-select
              v-model="busEditModel.ownType"
              placeholder="请选择车辆归属类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                label="自备车"
                value="1"
              >
              </el-option>
              <el-option
                label="外租车"
                value="2"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="自编号:">
            <el-input
              v-model="busEditModel.ownerCode"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="额定方量(方):" props="ratedCubage">
            <el-input
              v-model="busEditModel.ratedCubage"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="总方量(方):">
            <el-input
              v-model="busEditModel.totalCubage"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="额定质量(吨):" props="weightRated">
            <el-input
              v-model="busEditModel.weightRated"
              style="width: 180px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="总质量(吨):">
            <el-input
              v-model="busEditModel.totalWeight"
              style="width: 200px"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitBusEdit()" size="mini">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </PageContainer>
</template>

<script>
import { getComps } from '@/apis/common'
import { gianMonitorBusInfolist, setMonitorBusInfo, deleteMonitorBusInfo } from '@/apis/infoManage/busInfo'
import XEUtils from 'xe-utils'
import PageContainer from '../../components/common/PageContainer/index.vue'
export default {
  name: 'bus-info',
  data () {
    return {
      busEditModel: {
        busLicPlate: '',
        lwhOut: '',
        lwhTank: '',
        netWeight: 0,
        ownType: '',
        ownerCode: '',
        ratedCubage: 0,
        totalCubage: 0,
        totalWeight: 0,
        vehiId: '',
        vehiType: '',
        weightRated: 0,
        compId: ''
      },
      rules: {
        compId: [
          { required: true, message: '企业名称为必填选项', trigger: 'change' }
        ]
      },
      vehiTypeOptions: {
        1: '泵车',
        2: '混凝土搅拌车',
        3: '散装水泥车',
        4: '预拌砂浆车'
      },
      dialogEditTitle: '新增车辆',
      dialogEdit: false,
      searchModel: {
        busLicPlate: '',
        ownType: '',
        vehiType: '',
        compId: ''
      },
      ownTypeOptions: {
        1: '自备车',
        2: '外租车'
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      companyList: [],
      compListOptions: {}
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      this.loading = true
      gianMonitorBusInfolist({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
        this.$message({
          showClose: true,
          message: '请求异常',
          type: 'error'
        })
      })
    },
    addRow () {
      this.dialogEditTitle = '新增车辆'
      this.busEditModel = {
        busLicPlate: '',
        lwhOut: '',
        lwhTank: '',
        netWeight: 0,
        ownType: '',
        ownerCode: '',
        ratedCubage: 0,
        totalCubage: 0,
        totalWeight: 0,
        vehiId: '',
        vehiType: '',
        weightRated: 0,
        compId: ''
      }
    },
    editRow (row) {
      //  this.modifyDetailRow = row
      this.dialogEditTitle = '修改车辆'
      const rowData = {}
      XEUtils.objectEach(this.busEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.busEditModel = Object.assign({}, rowData)
      this.dialogEdit = true
    },
    deleteRow (row) {
      this.$confirm('确定删除该车辆信息?', '删除车辆', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        deleteMonitorBusInfo({
          ids: [row.vehiId]
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {
    },
    onSubmitBusEdit () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          setMonitorBusInfo({ ...this.busEditModel }).then(res => {
            if (res.code === 200) {
              if (this.busEditModel.vehiId != '') {
                this.$message.success('修改成功！')
              } else {
                this.$message.success('添加成功！')
              }
              this.dialogEdit = false
              this.refreshData()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  },
  mounted () {
    getComps({}).then(res => {
      if (res.code == 200) {
        this.companyList = res.data.rows
        const temp = {}
        this.companyList.forEach(item => {
          temp[item.compId] = item.compName
        })
        this.compListOptions = temp
      }
      this.refreshData()
    }).catch(res => {
      console.log(res)
    })
  },
  components: { PageContainer }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}

</style>
