import http from '@/utils/http'

// 车辆列表
export const gianBusInfolist = p => http.post('/mortconc/vehics/list', p)

// 车辆调整(新增或修改)
export const setBusInfo = p => http.post('/mortconc/vehics/set', p)

// 车辆删除
export const deleteBusInfo = p => http.post('/mortconc/vehics/del', p)

// 监控车辆列表
export const gianMonitorBusInfolist = p => http.post('/mortconc/vehics/list1', p)

// 监控车辆调整(新增或修改)
export const setMonitorBusInfo = p => http.post('/mortconc/vehics/set', p)

// 监控车辆删除
export const deleteMonitorBusInfo = p => http.post('/mortconc/vehics/del', p)

// 车辆列表
export const gianBusInfolist2 = p => http.post('/mortconc/vehics/list3', p)

// 车辆导入模版下载
export const downTemVehi = p => http.postFormExcel('/unibu/vehi/downloadTem', p)

// 车辆导入
export const importExcelVehi = p => http.postForm('/unibu/vehi/importExcel', p)
